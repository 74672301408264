<template>
  <div id="app">
    <HomeView/>
  </div>
</template>

<script>
import HomeView from './components/HomeView.vue'


export default {
  name: 'App',
  components: {
    HomeView,
  }
}
</script>

<style>
</style>
