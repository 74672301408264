<template>
  <div>
    <div class="enveloperain">
        <img src="../assets/static/右上角红包@3x.png" alt="">
    </div>
    <div class="navigation">
      <div class="navigation_img">
        <img src="../assets/static/元集市@3x.png" alt="" />
      </div>
      <div class="navigation_text">
        <span
          v-for="(item, index) in navigation"
          :key="index"
          @click="goTab(item.id)"
          :class="item.id == defaults ? 'appear' : ''"
        >
          {{ item.text }}
        </span>
      </div>
      <div class="navigation_btn" @click.stop="qrcodeShow">
        <img src="../assets/static/下载@3x(1).png" alt="" />
        下载元集市
      </div>
    </div>
    <div class="homePage" id="anchor1">
      <div class="homePage_left">
        <div class="homePage_img">
          <img
            style="width: 108px; height: 30px"
            src="../assets/static/元集市@3x.png"
            alt=""
          />
        </div>
        <div class="homePage_text">
          正品商家<br />
          与红包聚集地
        </div>
        <div class="homePage_shop">购物/抢红包/刷视频/聊天</div>
        <div class="homePage_download" @click.stop="qrcodeShow">
          <img src="../assets/static/下载@3x(1).png" alt="" />
          下载元集市
        </div>
        <!-- <div class="qrcode" v-show="dialog">
            <div id="qrcode" ref="qrCodeDiv" class="qrcode_ewm">
            </div>
            <div style="color: #333;margin-top:20px;">
              扫一扫立即下载
            </div>
          </div> -->
      </div>
      <div class="homePage_right">
        <img
          style="width: 100%px; height: 100%"
          src="../assets/官网图片/1.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="center">
      <div class="center_left">
        <img src="../assets/static/路径 6@3x.png" alt="" />
        <div class="center_spot">
          <img src="../assets/static/点1@3x.png" alt="" />
          <img src="../assets/static/点2@3x.png" alt="" />
          <img src="../assets/static/点3@3x.png" alt="" />
          <img src="../assets/static/点4@3x.png" alt="" />
        </div>
      </div>
      <div class="center_right">
        <div class="envelope" id="anchor2">
          <div class="envelope_left" :class="dialog?'envelopeLeftKey':''">
            <div>
              <span style="font-weight: 600; font-size: 40px; color: #181818"
                >红包</span
              >
              <img src="../assets/官网图片/2.png" alt="" />
            </div>
          </div>
          <div class="envelope_right" :class="dialog?'envelopeRightKey':''">
            <div class="envelope_common">
              公域<br />
              红包
              <div>有战斗力就可以抢</div>
            </div>
            <div class="envelope_xian">
              <img src="../assets/static/路径 5@3x(1).png" alt="" />
            </div>
            <div class="envelope_private">
              私域<br />
              红包
              <div>购物进商家群就可以抢</div>
            </div>
          </div>
        </div>
        <div class="shopping" id="anchor3">
          <div class="shopping_left" :class="dialog1?'shoppingLeftKey':''">
            <div>
              <span style="font-weight: 600; font-size: 40px; color: #181818"
                >购物</span
              >
            </div>
            <div>
              <span>100%正品保证<br />精选品质商家入驻<br />严选优质商品</span>
              <p>购物自动进商家群，并获得群积分可用于抢群红包，也可坐等升值</p>
            </div>
          </div>
          <div class="shopping_right" :class="dialog1?'shoppingRightKey':''">
            <div>
              <img src="../assets/官网图片/3.png" alt="" />
            </div>
          </div>
        </div>
        <div class="chat" id="anchor4">
          <div class="chat_left" :class="dialog2?'chatLeftKey':''">
            <div>
              <span style="font-weight: 600; font-size: 40px; color: #181818"
                >聊天</span
              >
              <img src="../assets/官网图片/6.gif" alt="" />
            </div>
          </div>
          <div class="chat_right" :class="dialog2?'chatRightKey':''">
            <div class="chat_common">
              群聊
              <div>商家群里每天都有红包抢<br />还可参与商家共建</div>
            </div>
            <div class="chat_xian">
              <img src="../assets/static/路径 5@3x(1).png" alt="" />
            </div>
            <div class="chat_private">
              1V1
              <div>聊天即可赚钱</div>
            </div>
          </div>
        </div>
        <div class="shortvideo" id="anchor5">
          <div class="shortvideo_left" :class="dialog3?'shortvideoLeftKey':''">
            <div>
              <span style="font-weight: 600; font-size: 40px; color: #181818"
                >短视频</span
              >
            </div>
            <div>
              <span>刷视频可获得战斗力<br />战斗力用于抢公域红包</span>
            </div>
          </div>
          <div class="shortvideo_right" :class="dialog3?'shortvideoRightKey':''">
            <div>
              <img src="../assets/官网图片/5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_one">
        <img src="../assets/static/元集市@3x(1).png" alt="" />
      </div>
      <div class="footer_tow">
        <span>购物、娱乐、抢红包一体的新一代互联网APP</span>
      </div>
      <div class="footer_four">
        <span>联系我们：QCP2024@163.com</span>
      </div>
      <div class="footer_three">
        <div class="footer_btn1" @click="qrcodeShow">
          <div>
            <img src="../assets/static/下载@3x(1).png" alt="" />
          下载元集市
          </div>
        </div>
        <div class="footer_btn2">
          <div>
            <img src="../assets/static/下载@3x(3).png" alt="" />
          iOS待上线
          </div>
        </div>
      </div>
    </div>
    <div class="footer_img">
      <div class="footer_img1">
         <img src="../assets/static/左红包@3x.png" alt="">
    </div>
    <div class="footer_img2">
      <img src="../assets/static/右红包@3x.png" alt="">
    </div>
    </div>
  </div>
</template>
<script>
// import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      navigation: [
        {
          id: 1,
          text: "首页",
        },
        {
          id: 2,
          text: "红包",
        },
        {
          id: 3,
          text: "购物",
        },
        {
          id: 4,
          text: "聊天",
        },
        {
          id: 5,
          text: "短视频",
        },
      ],
      defaults: 1,
      dialog:false,
      dialog1:false,
      dialog2:false,
      dialog3:false
    };
  },
  mounted() {
    this.elementList()
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    goTab(ids) {
      this.defaults = ids;
      switch(ids){
      case 1:
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
      break;
      case 2:
      window.scrollTo({
          top: this.envelope,
          behavior: "smooth"
      });
      break;
      case 3:
      window.scrollTo({
          top: this.shopping,
          behavior: "smooth"
      });
      break;
      case 4:
      window.scrollTo({
          top: this.chat,
          behavior: "smooth"
      });
      break;
      case 5:
      window.scrollTo({
          top: this.shortvideo,
          behavior: "smooth"
      });
      break;
    }
    },
    qrcodeShow(){
      const url = 'https://sj.qq.com/appdetail/com.csqcp.mobileuniverse'; // 文件的 URL 地址
      const link = document.createElement('a');
      link.href = url;
      // link.setAttribute('download', ''); // 设置文件属性为下载
      document.body.appendChild(link); // 将元素插入到 DOM 中
      link.click(); // 模拟点击下载链接进行下载
      document.body.removeChild(link); // 下载完成后移除元素
    },
    elementList(){
      this.envelope = document.getElementsByClassName('envelope')[0].offsetTop + 90
      this.shopping = document.getElementsByClassName('shopping')[0].offsetTop + 90
      this.chat = document.getElementsByClassName('chat')[0].offsetTop + 90
      this.shortvideo = document.getElementsByClassName('shortvideo')[0].offsetTop + 90
    },
    handleScroll () {
	        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
	        console.log(scrollTop, '滚动距离')
          console.log('-----------',this.envelope);
          if(scrollTop>=600){
            this.dialog = true
          } 
          if(scrollTop>=1750){
            this.dialog1 = true
          }
          if(scrollTop>=2850){
            this.dialog2 = true
          }
          if(scrollTop>=4200){
            this.dialog3 = true
          }
	    }
  },
};
</script>
<style lang="less">
@import '../style/home.less';
</style>